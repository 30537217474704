<script>
  import { push } from "svelte-spa-router";
  import authService from "../services/auth.service";

  export let params = {};

  $: checkParamsTkn(params);

  /**
   * @param {{tkn?: string; wild?: string;}} params
   */
  function checkParamsTkn(params) {
    const tkn = params?.tkn || params?.wild;
    if (!tkn) return;
    setTimeout(() => {
      activateSocial(tkn);
    }, 300);
  }

  /**
   * @param {string} tkn
   */
  function activateSocial(tkn) {
    if (!tkn) {
      push("/login");
      return;
    }
    authService.activateSocial(tkn, (err, res) => {
      if (err) {
        push("/login");
        return;
      }
      push("/home");
    });
  }
</script>

redirecting....
