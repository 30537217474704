<script>
  import { t, tNow } from "../../services/i18n.service";
  import {
    ChevronRight,
    Facebook,
    Google,
    Linkedin,
  } from "svelte-bootstrap-icons";
  import { onMount, onDestroy } from "svelte";
  import { push, querystring } from "svelte-spa-router";
  import authService from "../../services/auth.service";
  import toasterService from "../../services/toaster.service";
  import { userStore, platformInfoStore, themeStore } from "../../stores";
  import Theme from "../../components/utils/theme.svelte";
  import { parse } from "qs";
  import FieldInput from "../../components/utils/field-input.svelte";
  import FieldInputButton from "../../components/utils/field-input-button.svelte";
  import apiService from "../../services/api.service";
  import async from "async";
  import storageService from "../../services/storage.service";

  let showSignUp = false;
  let showInvitation = false;
  let onprem = true;
  let hasacc = true;
  /**
   * @type {{organisation: any, email: string|null, firstName: string|null, lastName: string|null, password: string|null, confirmationId: string|null, invitationId: string|null}} signform
   */
  let signform = {
    organisation: {},
    firstName: null,
    lastName: null,
    password: null,
    email: null,
    confirmationId: null,
    invitationId: null,
  };
  let params = null;
  let socialAuth = {};
  let showSocialAuth = false;
  let allowSignUp = false;

  let in1Type = "password";
  let in1Icon = "eyeslash";
  let messagePassInfo,
    showTerms = false,
    tnc = undefined;
  let user = {};
  let queryStringUnsubscribe;
  let qobj;

  let platformInfoStoreUnsubscribe = platformInfoStore.subscribe((res) => {
    if (!res) return;
    onprem = res.env === "onprem";
    hasacc = res.hasacc;
    socialAuth = res.socAuth;
    allowSignUp = res.sup;
    tnc = res.tnc;
    if (tnc) showTerms = true;
  });

  onMount(() => {
    queryStringUnsubscribe = querystring.subscribe(() => {
      checkQueryStrings();
    });
  });

  onDestroy(() => {
    queryStringUnsubscribe();
    platformInfoStoreUnsubscribe();
  });

  function checkQueryStrings() {
    if ($querystring) {
      /** @type {any} */
      qobj = parse($querystring);
      if (!qobj) return;
      if (qobj.newUser === "1") {
        signform.invitationId = qobj.invitationId;
        signform.email = qobj.email;
        showSignUp = true;
      } else {
        user.invitationId = qobj.invitationId;
        user.username = qobj.email;
      }
      if (qobj.signup) showSignUp = true;
      if (qobj.confirmationId && qobj.invitationId && qobj.email) {
        showInvitation = true;
        signform.email = qobj.email;
        signform.confirmationId = qobj.confirmationId;
        signform.invitationId = qobj.invitationId;
      }
    }
  }
  /**
   * @param {string} what
   * @param {any} user
   */
  function login(what, user) {
    if (qobj?.invitationId) user.invitationId = qobj.invitationId;
    authService.login(
      what,
      user,
      (
        /** @type {any} */ err,
        /** @type {{ redirect: any; url: string; }} */ res,
      ) => {
        if (err) {
          toasterService.msg({ type: "error", msg: String(err?.data?.error) });
          if (
            err.data?.error?.startsWith("[ERR 4455]") ||
            err.data?.error?.startsWith("[ERR 4456]")
          ) {
            storageService.save("email", user.username);
            push("/activate");
          }
          return;
        }
        if (res?.redirect) {
          push("/" + res.url);
        } else {
          userStore.set(res);
          push("/");
        }
      },
    );
  }

  function signin(which, data) {
    if (qobj?.invitationId) data.invitationId = qobj.invitationId;
    if (!data.hasAccount) {
      //if (!apiService.check.val(data.organisation.name)) {
      //  if (!data.invitationId)
      //    toasterService.warning({ msg: tNow("login.msgNoOrgName") });
      //  return;
      //}
      // if (!$api.check.email(data.organisation.email)) {
      // 	$api.err('warning', "Please fill in the Organisation email address");
      // 	return;
      // };
      if (!apiService.check.email(data.email)) {
        toasterService.warning({ msg: tNow("login.msgEmailInvalid") });
        return;
      }
      if (!apiService.check.val(data.firstName)) {
        toasterService.warning({ msg: tNow("login.msgFirstName") });
        return;
      }
      if (!apiService.check.val(data.lastName)) {
        toasterService.warning({ msg: tNow("login.msgLastName") });
        return;
      }
      if (!apiService.check.val(data.password)) {
        toasterService.warning({ msg: tNow("login.msgPass") });
        return;
      }

      // if (data.password != data.password2) {
      // 	$api.err('warning', $translate.instant('login.msgPassMatch'));
      // 	return;
      // };
    }
    if (data.hasAccount) {
      data.username = data.email;
    }

    data.organisation.email = data.email;

    async.waterfall(
      [
        (callback) => {
          // TODO: implement gRecaptcha
          callback();
        },
      ],
      (err, tkn) => {
        if ("local" == which) {
          authService.signin(data, tkn, onprem, (err, res) => {
            if (err) {
              if (res.messagePassInfo) messagePassInfo = res.messagePassInfo;
              return;
            }

            if (res.redirect) {
              push("/" + res.url);
            }
          });
        }
      },
    );
  }

  function forgotPassword() {
    push("/forgotPassword");
  }
</script>

<div>
  <div class="row">
    <div class="col-md-3 col-lg-4" />

    <!-- LOGIN -->
    {#if !showSignUp && !showInvitation}
      <div class="col-md-6 col-lg-4 mt-5">
        <div class="card-header d-flex flex-row-reverse">
          <div class="mb-3">
            <Theme />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              {#if $platformInfoStore?._n}
                {#if $platformInfoStore?._n?.n === "kpmg"}
                  {#if $themeStore === "light"}
                    <img
                      alt="Brand"
                      src="portal/imgs/logo_kpmg_dp.png"
                      style="width: 300px;"
                    />
                  {:else}
                    <img
                      alt="Brand"
                      src="portal/imgs/logo_kpmg_dp_white.png"
                      style="width: 300px;"
                    />
                  {/if}
                {:else if $platformInfoStore?._n?.n === "omni"}
                  <img
                    alt="Brand"
                    src="portal/imgs/logo_omniasig.png"
                    style="width: 300px;"
                  />
                {:else if $platformInfoStore?._n?.n === "omni"}
                  <img
                    alt="Brand"
                    src="portal/imgs/logo_omniasig.png"
                    style="width: 300px;"
                  />
                {:else if $platformInfoStore?._n?.n === "ngiq"}
                  {#if $themeStore === "light"}
                    <img
                      src="portal/imgs/logo_engineiq_light.png"
                      alt="Brand"
                      width="auto"
                      height="80"
                    />
                  {:else}
                    <img
                      src="portal/imgs/logo_engineiq_dark.png"
                      alt="Brand"
                      width="auto"
                      height="80"
                    />
                  {/if}
                {:else}
                  <div class="text-primary fw-bold text-uppercase fs-2">
                    {$platformInfoStore._n.f}
                  </div>
                {/if}
              {:else}
                <img alt="Brand" src="portal/imgs/logo_tasky_cloud.png" />
              {/if}
              <!-- <h2>{$t("login.titleLogin")}</h2> -->
              <br />
              <br />
              {#if $platformInfoStore?.sup}
                <p>
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- TODO: -->
                  Don't have an account?
                  <!-- svelte-ignore a11y-no-static-element-interactions -->
                  <a
                    class="btn btn-sm btn-outline-primary ms-3"
                    on:click={() => (showSignUp = true)}
                  >
                    {$t("login.titleSignin")}
                  </a>
                </p>
              {/if}
            </div>

            <div>
              <FieldInput
                label={$t("generic.email")}
                name="username"
                bind:value={user.username}
                on:key-enter={() => {
                  login("local", user);
                }}
              />
              <FieldInputButton
                label={$t("generic.pass")}
                bind:value={user.password}
                name="password"
                type={in1Type}
                buttonIcon={in1Icon}
                fireEvent="on-key"
                fn={(ev) => {
                  if (!ev) {
                    in1Type = in1Type === "text" ? "password" : "text";
                    in1Icon = in1Icon === "eyeslash" ? "eye" : "eyeslash";
                  } else if (ev.keyCode === 13) {
                    login("local", user);
                  }
                }}
              />
            </div>
            <div class="d-grid mt-3">
              <button
                on:click={() => {
                  login("local", user);
                }}
                class="btn btn-primary"
              >
                {$t("login.btnLogin")}
                <ChevronRight />
              </button>
            </div>

            <div class="text-center">
              {#if $platformInfoStore?.socAuth}
                <div class="text-center mt-3">
                  {#if $platformInfoStore.env !== "local"}
                    {#if $platformInfoStore.socAuth?.ggl || $platformInfoStore.socAuth?.fb || $platformInfoStore.socAuth?.lin}
                      or
                    {/if}
                    <div class="form-group mt-3">
                      <div class="controls">
                        {#if $platformInfoStore.socAuth?.ggl}
                          <a href="/api/auth_google" class="btn btn-google">
                            <Google width={24} height={24} />
                          </a>
                        {/if}
                        {#if $platformInfoStore.socAuth?.fb}
                          <a href="/api/auth_facebook" class="btn btn-facebook">
                            <Facebook width={24} height={24} />
                          </a>
                        {/if}
                        {#if $platformInfoStore.socAuth?.lin}
                          <a href="/api/auth_linkedin" class="btn btn-linkedin">
                            <Linkedin width={24} height={24} />
                          </a>
                        {/if}
                      </div>
                    </div>
                  {/if}
                </div>
              {/if}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <!-- svelte-ignore a11y-missing-attribute -->
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <a
                class="btn btn-outline-primary mt-4"
                on:click={() => {
                  forgotPassword();
                }}
              >
                {$t("login.forgotPass")}
              </a>
            </div>
          </div>
        </div>
      </div>
    {/if}

    <!-- SIGNUP -->

    {#if showSignUp && !showInvitation}
      <div class="col-md-6 col-lg-4 mt-5">
        <div class="card-header d-flex flex-row-reverse">
          <div class="mb-3">
            <Theme />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              {#if $platformInfoStore?._n}
                {#if $platformInfoStore?._n?.n === "kpmg"}
                  {#if $themeStore === "light"}
                    <img
                      alt="Brand"
                      src="portal/imgs/logo_kpmg_dp.png"
                      style="width: 300px;"
                    />
                  {:else}
                    <img
                      alt="Brand"
                      src="portal/imgs/logo_kpmg_dp_white.png"
                      style="width: 300px;"
                    />
                  {/if}
                {:else if $platformInfoStore?._n?.n === "omni"}
                  <img
                    alt="Brand"
                    src="portal/imgs/logo_omniasig.png"
                    style="width: 300px;"
                  />
                {:else if $platformInfoStore?._n?.n === "ngiq"}
                  {#if $themeStore === "light"}
                    <img
                      src="portal/imgs/logo_engineiq_light.png"
                      alt="Brand"
                      width="auto"
                      height="80"
                    />
                  {:else}
                    <img
                      src="portal/imgs/logo_engineiq_dark.png"
                      alt="Brand"
                      width="auto"
                      height="80"
                    />
                  {/if}
                {:else}
                  <div class="text-primary fw-bold text-uppercase fs-2">
                    {$platformInfoStore._n.f}
                  </div>
                {/if}
              {:else}
                <img alt="Brand" src="portal/imgs/logo_tasky_cloud.png" />
              {/if}
              <!-- <h2>{$t("login.titleSignin")}</h2> -->
              <br />
              <br />
              <p>
                <!-- TODO: -->
                Already have an account?
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-missing-attribute -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <a
                  class="btn btn-sm btn-outline-primary ms-3"
                  on:click={() => {
                    showSignUp = false;
                  }}>{$t("login.titleLogin")}</a
                >
              </p>
            </div>

            <div>
              <!-- <FieldInput -->
              <!--   label={$t("generic.org")} -->
              <!--   bind:value={signform.organisation.name} -->
              <!--   name="orgname" -->
              <!-- /> -->
              <div class="row">
                <div class="col-md-6">
                  <FieldInput
                    label={$t("login.firstName")}
                    bind:value={signform.firstName}
                    name="firstname"
                  />
                </div>
                <div class="col-md-6">
                  <FieldInput
                    label={$t("login.lastName")}
                    bind:value={signform.lastName}
                    name="lastname"
                  />
                </div>
              </div>
              <FieldInput
                label={$t("generic.email")}
                bind:value={signform.email}
                name="username"
                on:key-enter={() => {
                  signin("local", signform);
                }}
              />
              <FieldInputButton
                label={$t("generic.pass")}
                bind:value={signform.password}
                name="password"
                type={in1Type}
                buttonIcon={in1Icon}
                fireEvent="on-key"
                fn={(ev) => {
                  if (!ev) {
                    in1Type = in1Type === "text" ? "password" : "text";
                    in1Icon = in1Icon === "eyeslash" ? "eye" : "eyeslash";
                  } else if (ev.keyCode === 13) {
                    signin("local", signform);
                  }
                }}
              />
            </div>

            <div class="d-grid mt-3">
              <button
                on:click={() => {
                  signin("local", signform);
                }}
                class="btn btn-primary"
              >
                {$t("login.btnSignUp")}
                <ChevronRight width="24" height="24" />
              </button>
            </div>
            <div class="text-center">
              {#if messagePassInfo}
                <p class="alert alert-danger text-left mt-3">
                  {@html $t("diaChangePass.passInfo")}
                </p>
              {/if}
            </div>

            {#if showSocialAuth}
              <div class="text-center">
                <br />
                or
                {#if !onprem}
                  <div class="form-group text-center mt-3">
                    <div class="controls">
                      {#if socialAuth.ggl}
                        <a href="/api/auth_google" class="btn btn-google">
                          <Google width="24" height="24" />
                        </a>
                      {/if}
                      {#if socialAuth.fb}
                        <a href="/api/auth_facebook" class="btn btn-facebook">
                          <Facebook width="24" height="24" />
                        </a>
                      {/if}
                      {#if socialAuth.lin}
                        <a href="/api/auth_linkedin" class="btn btn-linkedin">
                          <Linkedin width="24" height="24" />
                        </a>
                      {/if}
                    </div>
                  </div>
                {/if}
              </div>
            {/if}

            {#if showTerms}
              <div class="text-center mt-3">
                {@html $t("login.tc.p1")}
                <br />
                <a
                  href={tnc.privacyStatement}
                  target="_blank"
                  rel="noreferrer"
                  class="text-primary"
                >
                  <b>{$t("login.tc.privacyStatement")}</b>
                </a>
                {@html $t("login.tc.p2")}
                <a
                  href={tnc.termsOfUse}
                  target="_blank"
                  rel="noreferrer"
                  class="text-primary"
                >
                  <b>{$t("login.tc.termsOfUse")}</b>
                </a>
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
  }
  .btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
  }
  .btn-linkedin {
    color: #fff;
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
  }
</style>
