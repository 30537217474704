<script>
  import { onDestroy } from "svelte";
  import FieldInputButton from "../components/utils/field-input-button.svelte";
  import FieldInput from "../components/utils/field-input.svelte";
  import { t } from "../services/i18n.service";
  import { push, querystring } from "svelte-spa-router";
  import { parse } from "qs";
  import authService from "../services/auth.service";

  let in1Type = "password";
  let in1Icon = "eyeslash";
  let in2Type = "password";
  let in2Icon = "eyeslash";
  let email = "",
    tokenSent = false,
    password = "",
    passwordConfirmation = "",
    verificationCode = "",
    showPassInfo = false,
    buttonName = "forgotPass.btnNameSendEmail";

  let queryStringUnsubscribe = querystring.subscribe(() => {
    checkQueryStrings();
  });

  onDestroy(() => {
    queryStringUnsubscribe();
  });

  function checkQueryStrings() {
    if ($querystring) {
      /** @type {any} */
      const qobj = parse($querystring);
      email = qobj?.email;
      verificationCode = qobj?.code;
      tokenSent = true;
      buttonName = "forgotPass.btnNameResetPass";
      // console.log("qobj");
    }
  }

  function resetPassword() {
    const data = {
      email,
      verificationCode,
      password,
      passwordConfirmation,
      tokenSent,
      fromForm: true,
      vers: "v5",
    };
    authService.forgotPassword(data, (err, res) => {
      if (err) {
        if (err?.response?.data?.showInfo) showPassInfo = true;
        return;
      }
      if (res.redirect) {
        push("/" + res.url);
      } else if (res.tokenSent) {
        tokenSent = true;
        buttonName = "forgotPass.btnNameResetPass";
      }
    });
  }

  function back() {
    tokenSent = false;
    verificationCode = null;
    email = null;
    password = null;
    passwordConfirmation = null;
    tokenSent = null;
    buttonName = "forgotPass.btnNameSendEmail";
    push("/forgotPassword");
  }
</script>

<div class="row">
  <div class="col-md-3" />

  <!-- FORGOT PASSWORD -->
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div>
          <h4>
            {$t("forgotPass.title")}
          </h4>
        </div>
        <FieldInput
          label={$t("generic.email")}
          placeholder={$t("generic.email")}
          bind:value={email}
          disabled={tokenSent}
        />
        {#if tokenSent}
          <p class="tsk-color-white">{$t("forgotPass.desc")}</p>
          <FieldInput
            label={$t("forgotPass.lblVerifCode")}
            bind:value={verificationCode}
            placeholder={$t("forgotPass.lblVerifCode")}
            name="verificationCode"
          />
          <FieldInputButton
            label={$t("forgotPass.lblNewPass")}
            placeholder={$t("generic.pass")}
            bind:value={password}
            name="password"
            type={in1Type}
            buttonIcon={in1Icon}
            fireEvent="on-key"
            fn={(ev) => {
              if (!ev) {
                in1Type = in1Type === "text" ? "password" : "text";
                in1Icon = in1Icon === "eyeslash" ? "eye" : "eyeslash";
              } else if (ev.keyCode === 13) {
                resetPassword();
              }
            }}
          />
          <FieldInputButton
            label={$t("forgotPass.lblNewPassConf")}
            placeholder={$t("forgotPass.phPassConf")}
            bind:value={passwordConfirmation}
            name="password"
            type={in2Type}
            buttonIcon={in2Icon}
            fireEvent="on-key"
            fn={(ev) => {
              if (!ev) {
                in2Type = in2Type === "text" ? "password" : "text";
                in2Icon = in2Icon === "eyeslash" ? "eye" : "eyeslash";
              } else if (ev.keyCode === 13) {
                resetPassword();
              }
            }}
          />

          {#if showPassInfo}
            <p class="alert alert-danger">
              {@html $t("diaChangePass.passInfo")}
            </p>
          {/if}
        {/if}
        <div class="d-grid mt-3">
          <button
            id="btn-signup"
            type="button"
            class="btn btn-primary"
            on:click={() => {
              resetPassword();
            }}
          >
            {$t(buttonName)}
          </button>

          {#if tokenSent}
            <div class="form-group mt-3">
              <div class="col-md-12">
                <div>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a
                    class="btn btn-outline-primary"
                    on:click={() => {
                      back();
                    }}
                  >
                    {$t("forgotPass.btnWrongEmail")}
                  </a>
                </div>
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" />
</div>
